import React from 'react';
import { useLocation } from '@reach/router';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';

import LightHeader from '../components/header/light-header';
import PageTitle from '../components/page/page-title';
import PageWrapper from '../components/page/page-wrapper';
import SEO from '../components/seo';
import { rhythm } from '../utils/typography';

const Post = styled.div`
  margin-bottom: ${rhythm(1)};
`;

const PostTitle = styled.h3`
  margin-top: 0;
  margin-bottom: ${rhythm(1 / 4)};
`;

const PostTitleLink = styled(Link)`
  box-shadow: none;
`;

export default function BlogPage({ data }) {
  const location = useLocation();
  const posts = data.allMarkdownRemark.edges;
  const siteUrl =
    typeof window !== 'undefined'
      ? location.origin
      : data.site.siteMetadata.siteUrl;

  return (
    <PageWrapper blog={true}>
      <SEO
        title="Blog"
        jsonLd={{
          '@type': 'Blog',
          blogPosts: posts.map(({ node }) => {
            return {
              '@type': 'BlogPosting',
              url: siteUrl + node.fields.slug,
              headline: node.frontmatter.title,
              image:
                siteUrl + node.frontmatter.thumbnail.childImageSharp.fluid.src,
            };
          }),
        }}
      />
      <LightHeader />
      <PageTitle>Blog</PageTitle>
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug;

        return (
          <Post key={node.fields.slug}>
            <PostTitle>
              <PostTitleLink to={node.fields.slug}>{title}</PostTitleLink>
            </PostTitle>
            <small>{node.frontmatter.date}</small>
            <p
              dangerouslySetInnerHTML={{
                __html: node.frontmatter.description + '…',
              }}
            />
          </Post>
        );
      })}
    </PageWrapper>
  );
}

export const pageQuery = graphql`
  query BlogQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { collection: { eq: "blog" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 600, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
